import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './style.scss'

export class DefaultSelect extends PureComponent {
  static propTypes = {
    items: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    zeroValueItem: PropTypes.bool,
    zeroValueText: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      items: null
    }
  }

  onBlur = e => {
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(e)
    }
  }

  render() {
    const {
      children,
      items,
      name,
      value,
      onChange,
      label,
      error,
      disabled,
      defaultValue,
      zeroValueItem,
      zeroValueText
    } = this.props

    const labelElement = label ? (
      <label htmlFor={name}>{label}</label>
    ) : null

    const errorElement = error ? (
      <div className="errormsg">{error}</div>
    ) : null

    let selectItems = items;
    if (zeroValueItem && items) {
      selectItems = [{ key: 0, value: zeroValueText || 'Välj...' }, ...items]
    }

    return (
      <div className="defaultSelect">
        {labelElement}
        <select
          name={name}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          className={classNames({ error: error })}
          disabled={disabled}
          onBlur={this.onBlur}>
          {
            children ? children : selectItems && selectItems.map(item => <option key={item.id || item.key} value={item.id || item.key}>{item.value || item.name}</option>)
          }
        </select>
        {errorElement}
      </div>
    )
  }
}

export default DefaultSelect
