import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.scss'

export default class StyledButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string,
  }

  onClick = () => {
    this.props.onClick(this.props.link);
  }

  render() {
    return (
      <div onClick={this.onClick} className="styledButton">
        <div>{this.props.text}</div>
      </div>
    )
  }
}
