import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import GridHeader from './GridHeader'
import GridItem from './GridItem'
import GridHeaderGroup from './GridHeaderGroup'
import AutoSizer from 'components/common/AutoSizer'

import classNames from 'classnames'
import { format } from 'lib/utils.js'
import { FixedSizeList as List } from 'react-window'

import {
  fetchCustomers,
  sortCustomers,
  setSalesperson,
  filterList
} from '../duck/'

import './style.scss'

export class CustomersList extends Component {
  divider = 1000;

  onClickCustomer = id => {
    this.props.history.push(`customer/${id}`);
  }

  fetchList = () => {
    this.props.dispatch(fetchCustomers());
  }

  onSort = (name) => {
    this.props.dispatch(sortCustomers(name));
  }

  getRow = ({ index, style }) => {
    const {
      items,
      filteredItems,
      filterActive
    } = this.props;

    const itemList = filterActive ? filteredItems : items;
    const item = itemList[index]

    let setColor = false;
    let positiveColor = null;
    if (item.salesYTD > 0 || item.salesYTDLastYear > 0) {
      setColor = true;
      positiveColor = item.salesYTDLastYear < item.salesYTD;
    }

    return (
      <GridItem key={item.id} id={item.id} onClick={this.onClickCustomer} style={style} odd={index % 2}>
        <div className="itemGroup row col-xs-12 col-sm">
          <div className="col-xs-2 col-sm-1">{item.id}</div>
          <div className="col-xs-5 col-sm-4">{item.name}</div>
          <div className="col-xs-3 xs-hide">{`${item.address.address1}${item.address != null && item.address.city.length ? ', ' + item.address.city : ''}`}</div>
          <div className={classNames('col-xs-2', 'right', { red: setColor && !positiveColor, green: setColor && positiveColor })}>{format(item.salesYTDLastYear / this.divider)}</div>
          <div className={classNames('col-xs-2', 'right', { red: setColor && !positiveColor, green: setColor && positiveColor })}>{format(item.salesYTD / this.divider)}</div>
        </div>
      </GridItem>
    )
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.filterActive && !this.props.filteredItems && this.props.items)
      this.props.dispatch(filterList());
  }

  componentDidMount() {
    if (!this.props.items) {
      this.props.dispatch(setSalesperson(this.props.user.username));
      this.props.dispatch(fetchCustomers());
    }
  }

  render() {
    const {
      items,
      filteredItems,
      filterActive,
      lng
    } = this.props;

    const itemList = filterActive ? filteredItems : items;

    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;

    return (
      <div className="customersList">
        <div className="headers row">
          <GridHeaderGroup className="col-xs-12 col-sm-12" title={lng.common.information}>
            <GridHeader
              className="col-xs-2 col-sm-1"
              title="Id"
              name="id"
              onClick={this.onSort} />
            <GridHeader
              className="col-xs-5 col-sm-4"
              title={lng.common.name}
              name="name"
              onClick={this.onSort} />
            <GridHeader
              className="col-xs-3 xs-hide"
              title={lng.common.address}
              name="address.address1"
              onClick={this.onSort} />
            <GridHeader
              className="col-xs-2 right"
              title={`YTD ${lastYear}`}
              name="salesYTDLastYear"
              onClick={this.onSort} />
            <GridHeader
              className="col-xs-2 right"
              title={`YTD ${currentYear}`}
              name="salesYTD"
              onClick={this.onSort} />
          </GridHeaderGroup>
        </div>
        <AutoSizer>
          {
            (height) => (
              <List
                height={height}
                items={itemList}
                itemCount={itemList ? itemList.length : 0}
                itemSize={36}>
                {this.getRow}
              </List>
            )
          }
        </AutoSizer>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  items: state.customers.items,
  filteredItems: state.customers.filteredItems,
  extended: state.customers.extended,
  showIds: state.customers.filter.showIds,
  filterActive: state.customers.filter.active,
  lng: state.app.language,
  user: state.login.user,
})

export default withRouter(connect(mapStateToProps)(CustomersList))
