import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './style.scss'

export class Button extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    meta: PropTypes.any,
    name: PropTypes.string,
  }

  onClick = e => {
    if (this.props.onClick !== undefined && this.props.meta !== undefined) {
      this.props.onClick(this.props.meta, e)
    }
    else if (this.props.onClick !== undefined) {
      this.props.onClick(e)
    }
  }

  render() {
    const {
      type,
      text,
      disabled,
      name
    } = this.props

    return (
      <button name={name} className={classNames('button', this.props.className)} disabled={disabled || false} onClick={this.onClick} type={type}>{text}</button>
    )
  }
}

export default Button
