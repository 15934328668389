const initialState = {
  loading: false,
  items: null
}

const actionPath = 'features/SalesUsers/'
const FETCH_SALES_USERS = actionPath + 'FETCH_SALES_USERS'
const FETCH_SALES_USERS_SUCCESS = actionPath + 'FETCH_SALES_USERS_SUCCESS'
const FETCH_SALES_USERS_FAILED = actionPath + 'FETCH_SALES_USERS_FAILED'

export const fetchSalesUsers = () => {
  return {
    types: [FETCH_SALES_USERS, FETCH_SALES_USERS_SUCCESS, FETCH_SALES_USERS_FAILED],
    callApi: {
      endpoint: 'api/salesUsers'
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SALES_USERS:
      return {
        ...state,
        loading: true
      }

    case FETCH_SALES_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }

    case FETCH_SALES_USERS_FAILED:
      return {
        ...state,
        loading: false,
        items: null
      }

    default:
      return state
  }
} 