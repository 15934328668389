const initialState = {
  loading: false,
  items: null
}

const actionPath = 'features/Customer/CustomerDeliveryTimes/';
const LOAD_DELIVERY_TIMES = actionPath + 'LOAD_DELIVERY_TIMES'
const LOAD_DELIVERY_TIMES_SUCCESS = actionPath + 'LOAD_DELIVERY_TIMES_SUCCESS'
const LOAD_DELIVERY_TIMES_FAILED = actionPath + 'LOAD_DELIVERY_TIMES_FAILED'

export const loadDeliveryTimes = (customerId) => {
  return {
    types: [LOAD_DELIVERY_TIMES, LOAD_DELIVERY_TIMES_SUCCESS, LOAD_DELIVERY_TIMES_FAILED],
    callApi: {
      endpoint: 'api/DeliveryTimes/' + customerId
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_DELIVERY_TIMES:
      return {
        ...state,
        loading: true,
        items: null
      }
    case LOAD_DELIVERY_TIMES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }
    case LOAD_DELIVERY_TIMES_FAILED:
      return {
        ...state,
        loading: false,
        items: null
      }
    default:
      return state
  }
}