const initialState = {
  loading: false,
  showInternal: true,
  data: null,
  error: false,
  showAllRebates: false
}

const actionPath = 'features/Customer/CustomerRebates/';

const TOGGLE_VIEW = actionPath + 'TOGGLE_VIEW';
const TOGGLE_CHECKBOX = actionPath + 'TOGGLE_CHECKBOX';

const FETCH_REBATES = actionPath + 'FETCH_REBATES';
const FETCH_REBATES_SUCCESS = actionPath + 'FETCH_REBATES_SUCCESS';
const FETCH_REBATES_FAILED = actionPath + 'FETCH_REBATES_FAILED';

export const changeView = () => {
  return {
    type: TOGGLE_VIEW
  }
}

export const fetchRebates = id => {
  return {
    types: [FETCH_REBATES, FETCH_REBATES_SUCCESS, FETCH_REBATES_FAILED],
    callApi: {
      endpoint: `api/Rebates/${id}`,
      processData: filterRebateData
    }
  }
}

export const toggleCheckbox = () => {
  return {
    type: TOGGLE_CHECKBOX
  }
}

export const filterRebateData = (data) => {
  data.forEach(item => {
    item.hasValues = item.discountPercent > 0 ||
      item.fullYear != null ||
      item.ltm != null ||
      item.ltmLastYear != null ||
      item.salesTrendPercentage != null ||
      item.ytd != null ||
      item.salesPriority === true
  });
  return data;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case TOGGLE_VIEW:
      return {
        ...state,
        showInternal: !state.showInternal
      }

    case FETCH_REBATES:
      return {
        ...state,
        loading: true,
        data: null,
        error: false
      }

    case FETCH_REBATES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      }

    case FETCH_REBATES_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }

    case TOGGLE_CHECKBOX:
      return {
        ...state,
        showAllRebates: !state.showAllRebates
      }

    default:
      return state
  }
}
