import {
  applyMiddleware,
  createStore,
  compose
} from 'redux'

import fetchMiddleware from './fetchMiddleware'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'
import unauthorizedMiddleware from './unauthorizedMiddleware'
//import * as Sentry from '@sentry/react';
import connectionMiddleware from './connectionMiddleware'

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//   // Optionally pass options
//   stateTransformer: state => {
//     const transformedState = {
//       ...state,
//       customer: null,
//       customers: {
//         ...state.customers,
//         filteredItems: null,
//         items: null,
//       },
//       app: {
//         ...state.app,
//         language: null,
//       }
//     }
//     return transformedState;
//   },
//   actionTransformer: action => {
//     //console.log(action, action.type.indexOf('_SUCCESS'))

//     if (action.type.indexOf('_SUCCESS') >= 0) {
//       return null
//     }
//     if(action.type === 'features/Customers/FETCH_CUSTOMERS_SUCCESS'){
//       return null 
//     }
//     return action;
//   },
// });

const middleware = applyMiddleware(
  thunk,
  fetchMiddleware,
  unauthorizedMiddleware,
  connectionMiddleware
)

let reduxDevTools = null
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
}

const composeEnhancers = reduxDevTools || compose

const store = createStore(
  rootReducer,
  composeEnhancers(middleware)
)

export default store