import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './style.scss'

export default class RadioButton extends PureComponent {

  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    hideAsterisk: PropTypes.bool,
    onChange: PropTypes.func,
    checked: PropTypes.bool.isRequired,
    value: PropTypes.any
  }

  render() {
    const {
      label,
      name,
      required,
      hideAsterisk,
      onChange,
      checked,
      value
    } = this.props

    return (
      <div className="radioButton">
        <label>
          <input type="radio"
            id={name}
            name={name}
            value={value}
            label={label}
            onChange={onChange}
            checked={checked} />
          {label} {required && !hideAsterisk ? <span className="required">*</span> : ''}
        </label>
      </div>
    )
  }
}
