import React, { Component } from 'react'
import { connect } from 'react-redux'

import LoadingOverlay from 'components/common/LoadingOverlay'

import PageHeader from 'components/common/PageHeader'
import CustomerDetails from './CustomerDetails'
import CustomerKeyMetrics from './CustomerKeyMetrics'
import CustomerStrategy from './CustomerStrategy'
import CustomerHistoricPurchases from './CustomerHistoricPurchases'
// import CustomerHistoricComplaints from './CustomerHistoricComplaints'
import CustomerDeliveryTimes from './CustomerDeliveryTimes'
import CustomerInteraction from './CustomerInteraction'
import CustomerRebates from './CustomerRebates'

import StyledButton from 'components/buttons/StyledButton'
import MessageBox from 'components/common/MessageBox'
import If from 'components/common/If'
import PrintingWindow from 'features/PrintingWindow'

import CustomerEdit from './CustomerEdit'

import './style.scss'

import {
  changeView,
  changeViewConfirmation,
  fetchCustomer,
} from './CustomerDetails/duck.js'

import {
  toggleEdit,
} from './CustomerEdit/duck'

import {
  toggleWindow
} from 'features/PrintingWindow/duck'

export class Customer extends Component {
  onClickRebate = () => {
    const id = this.props.match.params.id;
    const showRebates = this.props.match.params.view === 'rebates';
    if (!showRebates)
      this.props.history.push(`/customer/${id}/rebates`)
    else
      this.props.history.goBack();
  }

  onClickReturnToSalesEfficiency = () => {
    this.props.history.push('/salesefficiency');
  }

  onClickReturnToDashboard = () => {
    this.props.history.push('/')
  }

  onClickConfirmView = () => {
    this.props.dispatch(changeView());
    this.props.dispatch(changeViewConfirmation())
  }

  onClickCancelView = () => {
    this.props.dispatch(changeViewConfirmation())
  }

  onClickChangeViewConfirmation = () => {
    const showInternal = this.props.showInternal;
    if (!showInternal)
      this.props.dispatch(changeViewConfirmation());
    else {
      const id = this.props.match.params.id;
      this.props.dispatch(changeView());
      this.props.history.push(`/customer/${id}/`)
    }
  }

  onClickShowPrintingWindow = () => {
    this.props.dispatch(toggleWindow());
  }

  toggleCustomerEdit = () => {
    this.props.dispatch(toggleEdit())
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.saving && !this.props.saving) {
      this.props.dispatch(fetchCustomer(this.props.info.id));
    }
  }

  componentDidMount() {
    const info = this.props.info;
    const id = this.props.match.params.id;

    if (info === null || info.id !== id)
      this.props.dispatch(fetchCustomer(id));
  }

  render() {
    const {
      lng,
      loading,
      info,
      showInternal,
      showInternalConfirmation,
      editCustomer,
      showPrinting,
    } = this.props;

    const {
      id
    } = this.props.match.params;

    const translations = lng.customer;
    const showRebates = this.props.match.params.view === 'rebates';

    return (
      <div className="customer">
        {loading ? <LoadingOverlay /> : ''}
        <PageHeader title={info ? info.name : ''} feature={showInternal ? lng.customer.internalCustomerCard : lng.customer.customerCard} />
        <div className="row overview">
          <div className="details col-xs-12 col-sm-4">
            <div className="detailswrapper">
              <CustomerDetails onClickEdit={this.toggleCustomerEdit} />
              <CustomerDeliveryTimes customerId={id} />
              {showInternal ? <CustomerKeyMetrics /> : ''}
              {showInternal ? <CustomerStrategy /> : ''}
            </div>
            <div className="row detailNavigation">
              <If condition={showInternal}>
                <div className="col-xs-6">
                  <StyledButton
                    text={showRebates ? translations.buttons.overview : translations.buttons.rebates}
                    onClick={this.onClickRebate} />
                </div>
              </If>
              <div className="col-xs-6">
                <StyledButton
                  text={showInternal ? translations.buttons.external : translations.buttons.internal}
                  onClick={this.onClickChangeViewConfirmation}>
                </StyledButton>
              </div>
              <If condition={!showInternal}>
                <div className="col-xs-6">
                  <StyledButton
                    text={lng.common.printing}
                    onClick={this.onClickShowPrintingWindow} />
                </div>
              </If>
            </div>
          </div>
          <div className="data col-xs-12 col-sm-8">
            <If condition={!showRebates}>
              <CustomerHistoricPurchases id={id} />
              { /*<CustomerHistoricComplaints id={id} />*/}
              {showInternal ? <CustomerInteraction id={id} /> : ''}
            </If>
            {showRebates ? <CustomerRebates id={id} /> : ''}

            <div className="row navigation center-xs">
              <div className="btn col-xs-6">
                <StyledButton text={lng.common.customerCard}
                  onClick={this.onClickReturnToSalesEfficiency}></StyledButton>
              </div>
              <div className="btn col-xs-6">
                <StyledButton text={lng.common.dashboard}
                  onClick={this.onClickReturnToDashboard}></StyledButton>
              </div>
            </div>
          </div>
        </div>
        {
          showInternalConfirmation && !showInternal ?
            <MessageBox
              title={lng.common.confirm}
              textBody={lng.customer.messages.showInternal}
              onClickConfirm={this.onClickConfirmView}
              onClickCancel={this.onClickCancelView}
              confirmLabel={lng.common.yes}
              cancelLabel={lng.common.no} />
            : ''
        }
        {
          editCustomer ?
            <CustomerEdit/>
            : ''
        }
        {
          showPrinting && !showInternal ?
            <PrintingWindow /> : ''
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  loading: state.customer.details.loading,
  info: state.customer.details.info,
  showInternal: state.customer.details.showInternal,
  showInternalConfirmation: state.customer.details.showInternalConfirmation,
  editCustomer: state.customer.customerEdit.editCustomer,
  saving: state.customer.customerEdit.saving,
  showPrinting: state.printingWindow.showWindow,
})

export default connect(mapStateToProps)(Customer)