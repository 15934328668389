import initialState from './initialState'

import {
  CONNECTION_ISSUE
} from 'redux/commonTypes'

const FETCH_LANGUAGE = 'features/App/FETCH_LANGUAGE'
const FETCH_LANGUAGE_SUCCESS = 'features/App/FETCH_LANGUAGE_SUCCESS'
const FETCH_LANGUAGE_FAILED = 'features/App/FETCH_LANGUAGE_FAILED'

const CONNECTION_CHECK = 'features/App/CONNECTION_CHECK'
const CONNECTION_CHECK_SUCCES = 'features/App/CONNECTION_CHECK_SUCCES'
const CONNECTION_CHECK_FAILED = 'features/App/CONNECTION_CHECK_FAILED'

const FETCH_SETTINGS = 'features/App/FETCH_SETTINGS'
const FETCH_SETTINGS_SUCCESS = 'features/App/FETCH_SETTINGS_SUCCESS'
const FETCH_SETTINGS_FAILED = 'features/App/FETCH_SETTINGS_FAILED'

export const fetchLanguage = languageCode => {
  const payload = {
    languageCode
  }

  return {
    types: [FETCH_LANGUAGE, FETCH_LANGUAGE_SUCCESS, FETCH_LANGUAGE_FAILED],
    callApi: {
      endpoint: 'api/language/',
      method: 'GET',
      data: payload,
      localstorage: true
    },
    languageCode
  }
}

export const checkConnection = () => {
  return {
    types: [CONNECTION_CHECK, CONNECTION_CHECK_SUCCES, CONNECTION_CHECK_FAILED],
    callApi: {
      endpoint: 'api/connectionCheck/',
      method: 'GET',
    },
  }
}

export const fetchSettings = () => {
  return {
    types: [FETCH_SETTINGS, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_FAILED],
    callApi: {
      endpoint: 'api/settings'
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.languageCode
      }
    case FETCH_LANGUAGE_SUCCESS:
      return {
        ...state,
        language: action.data
      }
    case CONNECTION_ISSUE:
      return {
        ...state,
        connectionError: true,
      }
    case CONNECTION_CHECK: {
      return {
        ...state,
        connectionCheck: true,
        reconnectCount: state.reconnectCount + 1
      }
    }
    case CONNECTION_CHECK_SUCCES:
      return {
        ...state,
        connectionError: false,
        connectionCheck: false,
        reconnectCount: 0
      }
    case CONNECTION_CHECK_FAILED:
      return {
        ...state,
        connectionError: true,
        connectionCheck: false
      }
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        currencyCode: action.data.currencyCode,
        defaultLanguage: action.data.defaultLanguage
      }
    default:
      return state
  }
}