const initialState = {
  loading: false,
  items: null
}

const actionPath = 'features/customer/customerKeyMetrics/'
const FETCH_KEY_METRICS = actionPath + 'FETCH_KEY_METRICS'
const FETCH_KEY_METRICS_SUCCESS = actionPath + 'FETCH_KEY_METRICS_SUCCESS'
const FETCH_KEY_METRICS_FAILED = actionPath + 'FETCH_KEY_METRICS_FAILED'

export const fetchCustomerKeyMetrics = (id) => {
  return {
    types: [FETCH_KEY_METRICS, FETCH_KEY_METRICS_SUCCESS, FETCH_KEY_METRICS_FAILED],
    callApi: {
      endpoint: `api/CustomerKeyMetrics/${id}`      
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_KEY_METRICS:
      return {
        ...state,
        loading: true
      }

    case FETCH_KEY_METRICS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }

    case FETCH_KEY_METRICS_FAILED:
      return {
        ...state,
        loading: false,
        items: null
      }

    default:
      return state
  }
}