import StyledButton from 'components/buttons/StyledButton'
import FloatingWindow from 'components/common/FloatingWindow'

import PropTypes from 'prop-types'

import './style.scss'
import { Component } from 'react'

class MessageBox extends Component {
  static propTypes = {
    title: PropTypes.string,
    textBody: PropTypes.string,
    onClickConfirm: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    confirmLabel: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string.isRequired
  }

  render() {
    const {
      confirmLabel,
      cancelLabel
    } = this.props;

    return (
      <div className="messageBox">
        <FloatingWindow title={this.props.title} draggable={false} showHeader={true} closeOutSide={false}>
          <div className="messageContent center">
            {this.props.textBody}
          </div>
          <div className="row center-xs buttons">
            <StyledButton text={confirmLabel} onClick={this.props.onClickConfirm}></StyledButton>
            <StyledButton text={cancelLabel} onClick={this.props.onClickCancel}></StyledButton>
          </div>
        </FloatingWindow>
      </div>
    )
  }
}

export default MessageBox