import React, { Component } from 'react'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import PrivateRoute from 'components/common/PrivateRoute'
import { connect } from 'react-redux'

import Start from 'features/Start';
import Login from 'features/Login';
import NotFound from 'features/NotFound';
import Customers from 'features/Customers';
import Customer from 'features/Customer';
import LoadingOverlay from 'components/common/LoadingOverlay'
import ConnectionError from 'components/common/ConnectionError'
import Settings from 'features/Settings'

import {
  fetchUser
} from 'features/Login/duck'

import {
  fetchLanguage,
  fetchSettings,
  checkConnection
} from './duck'

import {
  getLanguageLocalStorage
} from 'lib/utils'

import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connectionTimer: null
    }
  }

  checkConnection = () => {
    if (this.state.connectionTimer != null)
      return;

    const pause = this.props.reconnectCount * 10000;
    const connectionTimer = setTimeout(() => {
      this.props.dispatch(checkConnection())
      this.setState({
        connectionTimer: null
      })
    }, pause)

    this.setState({
      connectionTimer
    })
  }

  fetchInitial = () => {
    this.props.dispatch(fetchUser())
    this.props.dispatch(fetchSettings())
    const languageCode = getLanguageLocalStorage();
    this.getLanguage(languageCode);
  }

  getLanguage = (languageCode) => {
    if (languageCode)
      this.props.dispatch(fetchLanguage(languageCode))
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.connectionError && this.props.connectionError)
      this.checkConnection();

    if (this.props.connectionError && prevProps.connectionCheck && !this.props.connectionCheck)
      this.checkConnection();

    if (this.props.defaultLanguage && !this.props.currentLanguage)
      this.getLanguage(this.props.defaultLanguage);
  }

  componentDidMount() {
    this.fetchInitial();
  }

  render() {
    const {
      lng,
      connectionError
    } = this.props;

    return (
      <Router>
        <div className="App">
          {connectionError ? <ConnectionError /> : null}
          {lng ? (
            <Switch>
              <Route component={Login} path="/login" exact />
              <PrivateRoute component={Start} path="/" authenticated={this.props.loggedIn} initialCheck={this.props.initialCheck} exact />
              <PrivateRoute component={Customers} path="/salesefficiency" authenticated={this.props.loggedIn} initialCheck={this.props.initialCheck} exact />
              <PrivateRoute component={Customer} path="/customer/:id/:view?" authenticated={this.props.loggedIn} initialCheck={this.props.initialCheck} exact />
              <PrivateRoute component={Settings} path="/settings" authenticated={this.props.loggedIn} initialCheck={this.props.initialCheck} exact />
              <Route component={NotFound} path="*" />
            </Switch>
          ) : <LoadingOverlay />}
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.login.loggedIn,
  initialCheck: state.login.initialCheck,
  lng: state.app.language,
  currentLanguage: state.app.currentLanguage,
  defaultLanguage: state.app.defaultLanguage,
  connectionError: state.app.connectionError,
  connectionCheck: state.app.connectionCheck,
  reconnectCount: state.app.reconnectCount
})

export default connect(mapStateToProps)(Sentry.withProfiler(App))
