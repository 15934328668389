import React, { Component } from 'react'
import { connect } from 'react-redux'

import LoadingOverlay from 'components/common/LoadingOverlay'

import './style.scss'

import {
  login
} from './duck'
import DefaultInput from 'components/inputs/DefaultInput'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: ''
    }
  }

  onChange = e => {
    const {
      name,
      value
    } = e.target

    this.setState({
      [name]: value
    })
  }

  onSubmit = e => {
    e.preventDefault()

    const {
      username,
      password
    } = this.state
    this.props.dispatch(login(username, password))
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.loggedIn)
      this.props.history.push('/');
  }

  render() {
    const {
      username,
      password
    } = this.state

    const {
      loading,
      invalidLogin
    } = this.props

    return (
      <div className="login">
        {invalidLogin ? <div className="error">Invalid login</div> : ''}
        <form onSubmit={this.onSubmit}>
          <DefaultInput
            type="text"
            name="username"
            label="Signatur"
            onChange={this.onChange}
            value={username}
            required
            hideAsterisk={true} />
          <DefaultInput
            type="password"
            name="password"
            label="Password"
            onChange={this.onChange}
            value={password}
            required
            hideAsterisk={true} />

          <button variant="success" type="submit">Login</button>
          {loading ? <LoadingOverlay /> : ''}
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.login.loading,
  invalidLogin: state.login.invalidLogin,
  loggedIn: state.login.loggedIn
})

export default connect(mapStateToProps)(Login)