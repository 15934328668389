import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageHeader from 'components/common/PageHeader'
import LoadingOverlay from 'components/common/LoadingOverlay'
import CustomersFilter from './CustomersFilter'
import CustomersList from './CustomersList'
import Swiper from 'components/common/Swiper'

import {
  showFilter
} from './duck/index'

import './style.scss'

export class Customers extends Component {
  onSwipedLeft = (data) => {
    this.props.dispatch(showFilter(false))
  }

  onSwipedRight = (data) => {
    this.props.dispatch(showFilter(true))
  }

  render() {
    const {
      loading,
      lng,
      showFilter
    } = this.props;

    return (
      <div className="customers">
        <Swiper onSwipedLeft={this.onSwipedLeft} onSwipedRight={this.onSwipedRight} delta={200}>
          {loading ? <LoadingOverlay /> : ''}
          <PageHeader title={lng.common.customerCard} />
          <CustomersFilter showMobile={showFilter} filterClick={showFilter ? this.onSwipedLeft : this.onSwipedRight} />
          <CustomersList />
        </Swiper>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  loading: state.customers.loading,
  showFilter: state.customers.filter.showFilter
})

export default connect(mapStateToProps)(Customers)
