import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import SalesUsers from 'features/SalesUsers'
import CustomerGroups from 'features/CustomerGroups'
import DefaultInput from 'components/inputs/DefaultInput'
import CustomerTypes from 'features/CustomerTypes'
import Button from 'components/buttons/Button'
import CheckBoxSelect from 'components/inputs/CheckBoxSelect'

import {
  filterList,
  resetFilter
} from '../duck'

import './style.scss'

class CustomersFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calculating: false,
      modifiedSalesUserArray: null
    }
  }

  onChange = e => {
    const {
      name,
      value
    } = e.target;
    this.props.dispatch(filterList(name, value))
  }

  onCheckboxSelectChange = (name, value) => {
    this.props.dispatch(filterList(name, value))
  }

  onResetFilter = () => {
    this.props.dispatch(resetFilter());
  }

  updateSalesUserArray = () => {
    const values = [...this.props.salesUsers];
    const noSalesPerson = { id: '-1', name: this.props.lng.customer.noSalesPerson };
    values.push(noSalesPerson)
    this.setState({
      modifiedSalesUserArray: values
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.state.modifiedSalesUserArray && this.props.salesUsers) {
      this.updateSalesUserArray();
    }
  }

  render() {
    const {
      filter,
      showMobile,
      lng,
      filterClick,
    } = this.props;

    const {
      modifiedSalesUserArray
    } = this.state

    const customerMarketTypes = [
      { id: 1, name: lng.common.personalVehicleTire },
      { id: 2, name: lng.common.truckTire },
      { id: 3, name: lng.common.loaderAndTractorTire }]

    return (
      <div className={classNames('customersFilter', { show: showMobile })}>
        <h3>{lng.customers.filters}</h3>
        <div className="filters row bottom-sm">
          <div className="col-xs-12 col-sm-2">
            <SalesUsers
              label={lng.customer.salesPerson}
              name="salesPerson"
              onChange={this.onChange}
              items={modifiedSalesUserArray}
              value={filter.salesPerson}
              showNoSalesPerson={true}
              zeroValueText={lng.common.all}
              zeroValueItem={true} />
          </div>
          <div className="col-xs-12 col-sm-2">
            <CustomerTypes
              label={lng.common.customerType}
              name="customerType"
              onChange={this.onChange}
              value={filter.customerType}
              zeroValueItem={true}
              zeroValueText={lng.common.all} />
          </div>
          <div className="col-xs-12 col-sm-2">
            <CustomerGroups
              name="customerGroup"
              onChange={this.onChange}
              value={filter.customerGroup}
            />
          </div>
          <div className="col-xs-12 col-sm-2">
            <CheckBoxSelect
              items={customerMarketTypes}
              label={lng.common.tyreType}
              values={filter.customerMarket}
              name="customerMarket"
              onChange={this.onCheckboxSelectChange}
              zeroValueText={lng.common.choose}
              zeroValueItem={true}
            />
          </div>
          <div className="col-xs-12 col-sm-3">
            <DefaultInput
              label={lng.customers.freeText}
              type="text"
              name="text"
              value={filter.text}
              onChange={this.onChange} />
          </div>
          <div className="col-xs-12 col-sm-1">
            <Button
              text={lng.common.reset}
              type="button"
              onClick={this.onResetFilter} />
          </div>
          <div className="showFilterButton" onClick={filterClick}>. . .</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  filter: state.customers.filter,
  items: state.customers.items,
  lng: state.app.language
})

export default connect(mapStateToProps)(CustomersFilter)