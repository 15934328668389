import DefaultInput from 'components/inputs/DefaultInput'
import SalesUsers from 'features/SalesUsers'
import CustomerTypes from 'features/CustomerTypes'

const DetailsEdit = ({ errors, language, values, customerTypes, salesPersons, salesStrategy, onChange }) => {
  return (
    <div>
      <h4 className="groupHeader">{language.customer.details} </h4>
      <DefaultInput
        error={errors.contactPerson}
        type="text"
        label={language.common.contactPerson}
        name="contactPerson"
        value={values.contactPerson}
        onChange={onChange} />
      <DefaultInput
        error={errors.emailAddress}
        type="text"
        label={language.common.email}
        name="emailAddress"
        value={values.emailAddress}
        onChange={onChange} />
      <DefaultInput
        error={errors.emailAddressWayBills}
        type="text"
        label={language.customer.emailWaybill}
        name="emailAddressWayBills"
        value={values.emailAddressWayBills}
        onChange={onChange} />
      <DefaultInput
        error={errors.emailAddressInvoices}
        type="text"
        label={language.customer.emailInvoice}
        name="emailAddressInvoices"
        value={values.emailAddressInvoices}
        onChange={onChange} />
      <DefaultInput
        error={errors.telephone}
        type="text"
        label={language.common.telephone}
        name="telephone"
        value={values.telephone}
        onChange={onChange} />
      <SalesUsers
        name="salesPerson"
        value={values.salesPerson}
        onChange={onChange}
        zeroValueItem={true} />
      {/* <DefaultSelect
        zeroValueText={language.common.choose}
        label={language.customer.strategy}
        name="strategy"
        items={salesStrategy}
        value={values.strategy}
        onChange={onChange}
        zeroValueItem={true} /> */}
      <CustomerTypes
        name="customerTypeId"
        value={values.customerTypeId || 0}
        onChange={onChange}
        zeroValueText={language.common.choose}
        zeroValueItem={true}
      />
    </div>
  )
}

export default DetailsEdit;