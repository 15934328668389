import React, { Component } from 'react'
import { connect } from 'react-redux'

import FloatingWindow from 'components/common/FloatingWindow'
import DefaultInput from 'components/inputs/DefaultInput'
import DefaultTextArea from 'components/inputs/DefaultTextArea'
import Button from 'components/buttons/Button'
import validationRules from './validationRules'
import { validateObject } from '@barnsten-it/object-validator'

import {
  toggleInteraction,
  insertCustomerInteraction,
  interactionChange,
} from '../duck'

import './style.scss'

export class InteractionWindow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errors: {}
    }
  }

  onClose = () => {
    this.props.dispatch(toggleInteraction());
  }

  onSaveInteraction = (e) => {
    e.preventDefault();
    const payload = {
      ...this.props.fields
    }

    const validation = validateObject(validationRules, payload)
    if (!validation.valid) {
      this.setState({
        errors: {
          ...validation.properties
        }
      })
      return;
    }
    this.props.dispatch(insertCustomerInteraction(this.props.customerInfo.id, payload));
    this.props.dispatch(toggleInteraction());
  }

  onChange = (e) => {
    const {
      name,
      value
    } = e.target

    this.props.dispatch(interactionChange(name, value))
  }

  render() {
    const {
      fields,
      lng
    } = this.props
    const errors = this.state.errors

    return (
      <div className="interactionWindow">
        <FloatingWindow title={lng.common.notes} showHeader={true} onClose={this.onClose} closeOutSide={false} draggable={false}>
          <form onSubmit={this.onSaveInteraction}>
            <DefaultInput
              error={errors.perticipants}
              type="text"
              label={lng.customer.perticipants}
              name="perticipants"
              value={fields.perticipants}
              onChange={this.onChange} />
            <DefaultInput
              error={errors.rationale}
              type="text"
              label={lng.customer.rationale}
              name="rationale"
              value={fields.rationale}
              onChange={this.onChange} />
            <DefaultTextArea
              error={errors.notes}
              label={lng.common.notes}
              name="notes"
              rows={8}
              value={fields.notes}
              onChange={this.onChange} />
            <div className="center">
              <Button type="submit" text={lng.common.save} onClick={this.onSaveInteraction} />
            </div>
          </form>
        </FloatingWindow>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  fields: state.customer.interactions.interactionFields,
  customerInfo: state.customer.details.info,
  lng: state.app.language
})

export default connect(mapStateToProps)(InteractionWindow)
