import React, { Component } from 'react'
import { connect } from 'react-redux'
import customerMarket from 'enums/customerMarket'

import ImageButton from 'components/buttons/ImageButton'
import { UserEditSolid } from 'icons'

import './style.scss'
import { changeView } from './duck'

export class CustomerDetails extends Component {
  onClickChangeView = (e) => {
    this.props.dispatch(changeView());
  }

  render() {
    const {
      info,
      lng,
      showInternal
    } = this.props;

    return (
      <div className="customerDetails">
        <div className="header row">
          <div className="col-xs-8">{lng.common.information}</div>
          <div className="col-xs-4 image row end-xs">
            <ImageButton onClick={this.props.onClickEdit} className="blue smal">
              <UserEditSolid />
            </ImageButton>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">{lng.customer.organisationNumber}</div>
          <div className="col-xs-6">{info ? info.organisationNumber : ''}</div>
        </div>
        <div className="row">
          <div className="col-xs-6">{lng.common.name}</div>
          <div className="col-xs-6">{info ? info.name : ''}</div>
        </div>
        <div className="row">
          <div className="col-xs-6">{lng.common.address}</div>
          <div className="col-xs-6">{info ? info.address.address1 : ''}</div>
        </div>
        <div className="row">
          <div className="col-xs-6">{lng.common.zipCode}</div>
          <div className="col-xs-6">{info ? info.address.zipCode : ''}</div>
        </div>
        <div className="row">
          <div className="col-xs-6">{lng.common.city}</div>
          <div className="col-xs-6">{info ? info.address.city : ''}</div>
        </div>
        <div className="row">
          <div className="col-xs-6">{lng.common.contactPerson}</div>
          <div className="col-xs-6">{info ? info.contactPerson : ''}</div>
        </div>
        <div className="row">
          <div className="col-xs-6">{lng.common.telephone}</div>
          <div className="col-xs-6">{info ? info.telephone : ''}</div>
        </div>
        { showInternal ?
          <div className="row">
            <div className="col-xs-6">{lng.common.bonusAgreement}</div>
            <div className="col-xs-6">{info ? info.hasBonusAgreement ? lng.common.yes : lng.common.no : ''}</div>
          </div> : ''
        }
        {
          info && info.customerGroups && info.customerGroups.length && showInternal ?
            <div className="row">
              <div className="col-xs-6">{lng.common.customerGroups}</div>
              <div className="col-xs-6">{info ? info.customerGroups.map(group => (<div key={group.key}>{group.value}</div>)) : ''}</div>
            </div> : ''
        }
        {
          info && info.customerMarketTypes && info.customerMarketTypes.length ?
            <div className="row">
              <div className="col-xs-6">{lng.customer.workingWith}</div>
              <div className="col-xs-6">{info ? info.customerMarketTypes.map(type =>
                type === customerMarket.personalVehicle ? (<div>{lng.common.personalVehicleTire}</div>) :
                  type === customerMarket.truck ? (<div>{lng.common.truckTire}</div>) :
                    type === customerMarket.loaderAndTractor ? (<div>{lng.common.loaderAndTractorTire}</div>) : '')
                : null}
              </div>
            </div> : ''
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  info: state.customer.details.info,
  showInternal: state.customer.details.showInternal,
  lng: state.app.language
})

export default connect(mapStateToProps)(CustomerDetails)