const initialState = {
  loading: false,
  showInternal: true,
  info: null,
  error: false,
  showInternalConfirmation: false,
  showPrinting: false,
}

const actionPath = 'features/Customer/CustomerDetails/';

const TOGGLE_VIEW = actionPath + 'TOGGLE_VIEW';
const TOGGLE_CONFIRM = actionPath + 'TOGGLE_CONFIRM';
const TOGGLE_PRINTING = 'TOGGLE_PRINTING';

const FETCH_CUSTOMER = actionPath + 'FETCH_CUSTOMER';
const FETCH_CUSTOMER_SUCCESS = actionPath + 'FETCH_CUSTOMER_SUCCESS';
const FETCH_CUSTOMER_FAILED = actionPath + 'FETCH_CUSTOMER_FAILED';

export const changeView = () => {
  return {
    type: TOGGLE_VIEW
  }
}

export const changeViewConfirmation = () => {
  return {
    type: TOGGLE_CONFIRM
  }
}

export const showPrintingWindow = () => {
  return {
    type: TOGGLE_PRINTING
  }
}

export const fetchCustomer = id => {
  return {
    types: [FETCH_CUSTOMER, FETCH_CUSTOMER_SUCCESS, FETCH_CUSTOMER_FAILED],
    callApi: {
      endpoint: `api/Customer/${id}`
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case TOGGLE_VIEW:
      return {
        ...state,
        showInternal: !state.showInternal
      }

    case TOGGLE_CONFIRM:
      return {
        ...state,
        showInternalConfirmation: !state.showInternalConfirmation
      }

    case TOGGLE_PRINTING:
      return {
        ...state,
        showPrinting: !state.showPrinting
      }

    case FETCH_CUSTOMER:
      return {
        ...state,
        loading: true,
        info: null,
        error: false
      }

    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.data
      }

    case FETCH_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }

    default:
      return state
  }
}
