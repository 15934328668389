const initialState = {
  loading: false,
  items: null
}

const actionPath = 'features/CustomerGroups/'
const FETCH_GROUPS = actionPath + 'FETCH_GROUPS'
const FETCH_GROUPS_SUCCESS = actionPath + 'FETCH_GROUPS_SUCCESS'
const FETCH_GROUPS_FAILED = actionPath + 'FETCH_GROUPS_FAILED'

export const fetchGroups = () => {
  return {
    types: [FETCH_GROUPS, FETCH_GROUPS_SUCCESS, FETCH_GROUPS_FAILED],
    callApi: {
      endpoint: 'api/customerGroups'
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUPS:
      return {
        ...state,
        loading: true
      }

    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }

    case FETCH_GROUPS_FAILED:
      return {
        ...state,
        loading: false,
        items: null
      }

    default:
      return state
  }
}