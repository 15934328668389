import React, { Component } from 'react'
import classNames from 'classnames'

import './style.scss'

export default class GridItem extends Component {
  onClick = () => {
    this.props.onClick(this.props.id);
  }

  render() {
    return (
      <div className={classNames('gridItem', 'row', {'odd': this.props.odd})} onClick={this.onClick} style={this.props.style}>
        {this.props.children}
      </div>
    )
  }
}
