import { Component } from 'react'
import { connect } from 'react-redux'

import CustomerCardPrinting from './CustomerCardPrinting'
import ArticleListPrinting from './ArticleListPrinting'

import FloatingWindow from 'components/common/FloatingWindow'
import Button from 'components/buttons/Button'
import LoadingOverlay from 'components/common/LoadingOverlay'
import StyledButton from 'components/buttons/StyledButton'
import If from 'components/common/If'
import DefaultInput from 'components/inputs/DefaultInput'

import { validateObject } from '@barnsten-it/object-validator'
import validationRules from './validationRules'

import './style.scss'

import {
  toggleWindow,
  resetWindow,
  showArticleList,
  showCustomerCard,
  emailCustomerCardPdf,
  emailArticleStatistics,
  setValue,
  setValidation
} from './duck.js'

export class PrintingWindow extends Component {
  onToggleComponent = (e) => {
    const {
      name,
    } = e.target

    switch (name) {
      case 'customerCard':
        this.props.dispatch(showCustomerCard())
        break;
      case 'articleList':
        this.props.dispatch(showArticleList())
        break;
      default:
        break;
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const validation = validateObject(validationRules, this.props.values)
    this.props.dispatch(setValidation(validation.value, validation.properties))

    if (!validation.valid)
      return;

    if (this.props.values.emailAddress && this.props.values.emailAddress.length) {
      var payload = {
        ...this.props.values,
        customerId: this.props.customerId
      }

      if (this.props.showCustomerCard) {
        this.props.dispatch(emailCustomerCardPdf(payload))
      }
      else {
        this.props.dispatch(emailArticleStatistics(payload))
      }
    }
  }

  onChange = (e) => {
    const {
      name,
      value
    } = e.target;

    this.props.dispatch(setValue(name, value));
  }

  goBack = () => {
    this.props.dispatch(resetWindow())
  }

  onClose = () => {
    this.props.dispatch(toggleWindow());
  }

  getHeader = () => {
    if (!this.props.toggleButtons)
      return this.props.lng.printing.print
    else
      return this.props.showCustomerCard ? this.props.lng.printing.customerCard : this.props.lng.printing.articleList
  }

  componentDidUpdate(prevProps) {
    if (this.props.success !== prevProps.success && this.props.success) {
      this.onClose()
    }
  }

  componentDidMount() {
    this.props.dispatch(setValue('emailAddress', this.props.emailAddress))
  }

  render() {
    const {
      lng,
      showArticleList,
      showCustomerCard,
      loading,
      languageCode,
      values,
      validationErrors,
    } = this.props;

    const hasAnySelected = showArticleList || showCustomerCard

    return (
      <div className="printingWindow">
        <FloatingWindow title={this.getHeader()} draggable={false} showHeader={true} closeOutSide={false} onClose={this.onClose}>
          <div className="printingWindowContainer column col-xs-12">
            <If condition={!hasAnySelected}>
              <div className="row center-xs">
                <div className="col-xs-6">
                  <Button type="button" text={lng.printing.customerCard} name="customerCard" onClick={this.onToggleComponent}></Button>
                </div>
                <div className="col-xs-6">
                  <Button type="button" text={lng.printing.articleList} name="articleList" onClick={this.onToggleComponent}></Button>
                </div>
              </div>
            </If>
            <div className="row col-xs-12">
              <form className="column col-xs-12" onSubmit={this.onSubmit}>
                <If condition={showCustomerCard || showArticleList}>
                  <DefaultInput
                    error={validationErrors.emailAddress}
                    label={lng.common.email}
                    type="email"
                    name="emailAddress"
                    value={values.emailAddress}
                    onChange={this.onChange}
                  />
                </If>
                <If condition={showCustomerCard}>
                  <CustomerCardPrinting
                    lng={lng}
                    customerId={this.props.customerId}
                    languageCode={languageCode}
                  />
                </If>
                <If condition={showArticleList}>
                  <ArticleListPrinting
                    lng={lng}
                    articleType={values.articleType}
                    customerId={this.props.customerId}
                    languageCode={languageCode}
                    onChange={this.onChange}
                  />
                </If>
              </form>
            </div>
            <If condition={hasAnySelected}>
              <div className="row navigation center-xs">
                <StyledButton text={'Tillbaka'} name="backTrace" onClick={this.goBack}></StyledButton>
              </div>
            </If>
          </div>
          {loading ? <LoadingOverlay /> : ''}
        </FloatingWindow>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  showCustomerCard: state.printingWindow.showCustomerCard,
  showArticleList: state.printingWindow.showArticleList,
  values: state.printingWindow.values,
  toggleButtons: state.printingWindow.toggleButtons,
  loading: state.printingWindow.loading,
  success: state.printingWindow.success,
  isValid: state.printingWindow.isValid,
  validationErrors: state.printingWindow.validationErrors,
  customerId: state.customer.details.info.id,
  emailAddress: state.customer.details.info.emailAddress,
  languageCode: state.app.currentLanguage,
  lng: state.app.language,
})

export default connect(mapStateToProps)(PrintingWindow)