
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.scss'

export default class FeatureButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string.isRequired
  }

  onClick = () => {
    this.props.onClick(this.props.link);
  }

  render() {
    return (
      <div onClick={this.onClick} className="featureButton">
        {this.props.children ? this.props.children : '...'}
        <div>{this.props.text}</div>
      </div>
    )
  }
}
