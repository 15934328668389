import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.scss'

export default class GridHeaderGroup extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired
  }

  render() {
    const {
      title,
      className
    } = this.props

    return (
      <div key={title} className={classNames('headerGroup', className)}>
        <div className="groupName">{title}</div>
        <div className="items">
          {this.props.children}
        </div>
      </div>
    )
  }
}
