const initialState = {
  showWindow: false,
  showCustomerCard: false,
  showArticleList: false,
  error: false,
  loading: false,
  success: false,
  isValid: true,
  validationErrors: [],
  values: {
    emailAddress: '',
    articleType: '1',
  }
}

const actionPath = 'features/PrintingWindow/';

const TOGGLE_WINDOW = actionPath + 'TOGGLE_WINDOW';
const RESET_WINDOW = actionPath + 'RESET_WINDOW';

const TOGGLE_CUSTOMERCARD = actionPath + 'TOGGLE_CUSTOMERCARD';
const TOGGLE_ARTICLELIST = actionPath + 'TOGGLE_ARTICLELIST';

const EMAIL_PDF = actionPath + 'EMAIL_PDF';
const EMAIL_PDF_SUCCESS = actionPath + 'EMAIL_PDF_SUCCESS';
const EMAIL_PDF_FAILED = actionPath + 'EMAIL_PDF_FAILED';

const EMAIL_ARTICLE_STATISTICS = actionPath + 'ARTICLE_STATISTICS';
const EMAIL_ARTICLE_STATISTICS_SUCCESS = actionPath + 'ARTICLE_STATISTICS_SUCCESS';
const EMAIL_ARTICLE_STATISTICS_FAILED = actionPath + 'ARTICLE_STATISTICS_FAILED';

const SET_VALUE = actionPath + 'SET_VALUE';

const SET_VALIDATION = actionPath + 'SET_VALIDATION';

export const toggleWindow = () => {
  return {
    type: TOGGLE_WINDOW
  }
}

export const resetWindow = () => {
  return {
    type: RESET_WINDOW
  }
}

export const showCustomerCard = () => {
  return {
    type: TOGGLE_CUSTOMERCARD
  }
}

export const showArticleList = () => {
  return {
    type: TOGGLE_ARTICLELIST
  }
}

export const setValue = (name, value) => {
  return {
    type: SET_VALUE,
    name,
    value
  }
}

export const setValidation = (isValid, validationErrors) => {
  return {
    type: SET_VALIDATION,
    isValid,
    validationErrors
  }
}

export const emailCustomerCardPdf = (emailCriteria) => {  
  return {
    types: [EMAIL_PDF, EMAIL_PDF_SUCCESS, EMAIL_PDF_FAILED],
    callApi: {
      endpoint: 'api/Email/',
      method: 'POST',
      data: emailCriteria
    }
  }
}

export const emailArticleStatistics = (emailCriteria) => {
  return {
    types: [EMAIL_ARTICLE_STATISTICS, EMAIL_ARTICLE_STATISTICS_SUCCESS, EMAIL_ARTICLE_STATISTICS_FAILED],
    callApi: {
      endpoint: 'api/Excel/ArticleStatistics',
      method: 'GET',
      data: emailCriteria
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case TOGGLE_WINDOW:
      return {
        ...state,
        showWindow: !state.showWindow,
        showCustomerCard: false,
        showArticleList: false
      }

    case RESET_WINDOW:
      return {
        ...state,
        showCustomerCard: false,
        showArticleList: false
      }

    case TOGGLE_CUSTOMERCARD:
      return {
        ...state,
        showCustomerCard: !state.showCustomerCard
      }

    case TOGGLE_ARTICLELIST:
      return {
        ...state,
        showArticleList: !state.showArticleList
      }

    case EMAIL_PDF:
      return {
        ...state,
        error: false,
        loading: true
      }

    case EMAIL_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      }

    case EMAIL_PDF_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
        success: false
      }

    case EMAIL_ARTICLE_STATISTICS:
      return {
        ...state,
        error: false,
        loading: true,
      }

    case EMAIL_ARTICLE_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      }

    case EMAIL_ARTICLE_STATISTICS_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
        success: false
      }

    case SET_VALUE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.name]: action.value
        }
      }

    case SET_VALIDATION:
      return {
        ...state,
        isValid: action.isValid,
        validationErrors: action.validationErrors
      }

    default:
      return state
  }
}
