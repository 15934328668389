import initialState from './initialState'

import {
  COMMON_LOGGEDIN,
  COMMON_LOGGEDOUT
} from 'redux/commonTypes'

const SEND_LOGIN = 'features/Login/SEND_LOGIN'
const SEND_LOGIN_FAILED = 'features/Login/SEND_LOGIN_FAILED'

// const CHECK_LOGIN = 'features/Login/CHECK_LOGIN'
// const CHECK_LOGIN_SUCCESS = 'features/Login/CHECK_LOGIN_SUCCESS'
// const CHECK_LOGIN_FAILED = 'features/Login/CHECK_LOGIN_FAILED'

const LOGOUT = 'features/Login/LOGOUT'
const LOGOUT_FAILED = 'features/Login/LOGOUT_FAILED'

const FETCH_USER = 'features/Login/FETCH_USER'
const FETCH_USER_SUCCESS = 'features/Login/FETCH_USER_SUCCESS'
const FETCH_USER_FAILED = 'features/Login/FETCH_USER_FAILED'

//Actions
export const login = (username, password) => {
  const postData = {
    username,
    password
  }

  return {
    types: [SEND_LOGIN, COMMON_LOGGEDIN, SEND_LOGIN_FAILED],
    callApi: {
      endpoint: 'api/Account',
      method: 'POST',
      data: postData
    }
  }
}

// export const checkLogin = () => {
//   return {
//     types: [CHECK_LOGIN, CHECK_LOGIN_SUCCESS, CHECK_LOGIN_FAILED],
//     callApi: {
//       endpoint: 'api/Account',
//       method: 'GET'
//     }
//   }
// }

export const logout = () => {
  return {
    types: [LOGOUT, COMMON_LOGGEDOUT, LOGOUT_FAILED],
    callApi: {
      endpoint: 'api/Account',
      method: 'DELETE'
    }
  }
}

export const fetchUser = () => {
  return {
    types: [FETCH_USER, FETCH_USER_SUCCESS, FETCH_USER_FAILED],
    callApi: {
      endpoint: 'api/Account',
      method: 'GET'
    }
  }
}

//Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_LOGGEDOUT:
    case LOGOUT:
      return {
        ...state,
        user: null,
        loggedIn: false
      }
    case COMMON_LOGGEDIN:
      return {
        ...state,
        loading: false,
        user: action.data.user,
        loggedIn: true
      }
    case SEND_LOGIN:
      return {
        ...state,
        loading: true,
        loggedIn: false,
        error: null,
        invalidLogin: false,
      }
    case SEND_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        user: null,
        loggedIn: false,
        error: action.error,
        invalidLogin: true
      }
    case FETCH_USER:
      return {
        ...state,
        loading: true
      }
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        initialCheck: true,
        user: action.data,
      }
    case FETCH_USER_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
        loggedIn: false,
        initialCheck: true
      }
    default:
      return state
  }
}