import React, { Component } from 'react'
import { connect } from 'react-redux'

// import ImageWrapper from 'components/common/ImageWrapper'

// import { ChartLineSolid } from 'icons'
// import salesStrategy from 'enums/salesStrategy'

import './style.scss'

export class CustomerStrategy extends Component {
  render() {
    const {
      info,
      lng
    } = this.props;
    // const strategy = info && Number(info.strategy);

    return (
      <div className="customerStrategy">
        <div className="header">{lng.customer.strategy}</div>
        <div className="row">
          <div className="col-xs-6">{lng.common.customerType}</div>
          <div className="col-xs-6">{info && info.customerTypeName ? info.customerTypeName : '-'}</div>
        </div>
        <div className="row">
          <div className="col-xs-6">{lng.customer.salesPerson}</div>
          <div className="col-xs-6">{info && info.salesPerson ? info.salesPersonName : '-'}</div>
        </div>
        { /* 
        <div className="row center bold">
          <div className="col-xs-12">{lng.customer.strategy}</div>
        </div>
        <div className="row center-xs">
          <div className="col-xs-3 row center-xs">
            <ImageWrapper text={lng.customer.flagShipAccount} border={true} showBorder={strategy === salesStrategy.flagShipAccount}>
              <ChartLineSolid />
            </ImageWrapper>
          </div>
          <div className="col-xs-3 row center-xs">
            <ImageWrapper text={lng.customer.investToGrow} border={true} showBorder={strategy === salesStrategy.investToGrow}>
              <ChartLineSolid />
            </ImageWrapper>
          </div>
          <div className="col-xs-3 row center-xs">
            <ImageWrapper text={lng.customer.maintain} border={true} showBorder={strategy === salesStrategy.maintain}>
              <ChartLineSolid />
            </ImageWrapper>
          </div>
          <div className="col-xs-3 row center-xs">
            <ImageWrapper text={lng.customer.serveEfficiently} border={true} showBorder={strategy === salesStrategy.serveEfficiently}>
              <ChartLineSolid />
            </ImageWrapper>
          </div>
        </div>
        */ }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  info: state.customer.details.info,
  lng: state.app.language
})

export default connect(mapStateToProps)(CustomerStrategy)