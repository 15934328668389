import React, { Component } from 'react'
import Button from 'components/buttons/Button'
import PropTypes from 'prop-types'

export class CustomerCardPrinting extends Component {
  static propTypes = {
    lng: PropTypes.object.isRequired,
    customerId: PropTypes.number.isRequired,
    languageCode: PropTypes.string,
  }

  onClick = () => {
    window.open(`/api/Pdf/CustomerCard/${this.props.languageCode}/${this.props.customerId}`, '_blank');
  }

  render() {
    const {
      lng
    } = this.props;

    return (
      <div className="formButtons column">
        <div className="customerCardButtons row center-xs">
          <div className="col-xs-6">
            <Button type="button" text={lng.printing.buttons.fetchPdf} onClick={this.onClick} />
          </div>
          <div className="col-xs-6">
            <Button type="submit" text={lng.printing.buttons.sendEmail} />
          </div>
        </div>
      </div>
    )
  }
}

export default CustomerCardPrinting;