import React from 'react'
import CloudSolid from 'icons/CloudSolid'

import './style.scss'

const LoadingCloud = (props) => (
  <div className="loadingCloud">
    <div className="wrapper">
      <div>
        <div className="cloud first">
          <CloudSolid />
        </div>
        <div className="cloud second">
          <CloudSolid />
        </div>
      </div>
      <div>{props.text}</div>
    </div>
  </div>
)

export default LoadingCloud