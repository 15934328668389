import React, { Component } from 'react'
import { connect } from 'react-redux'
import FloatingWindow from 'components/common/FloatingWindow'
import Checkbox from 'components/inputs/Checkbox'
import DefaultInput from 'components/inputs/DefaultInput'
import Button from 'components/buttons/Button'
import LoadingOverlay from 'components/common/LoadingOverlay'
import validationRules from './validationRules'
import { validateObject } from '@barnsten-it/object-validator'
import salesStrategy from 'enums/salesStrategy'
import DetailsEdit from './DetailsEdit'
import CustomerGroupsEdit from './CustomerGroupsEdit'
import customerMarket from 'enums/customerMarket'
import ErrorMessage from 'components/common/ErrorMessage'

import './style.scss'

import {
  updateCustomer,
  toggleEdit,
} from './duck'

export class CustomerEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errors: {},
      salesStrategy: [],
      values: {
        contactPerson: '',
        emailAddress: '',
        emailAddressWayBills: '',
        emailAddressInvoices: '',
        salesPerson: '',
        telephone: '',
        salesStrategy: '',
        customerTypeId: '',
        grossPotential: 0,
        totalSales: 0,
        customerMarketTypes: [],
        customerGroups: []
      }
    }
  }

  onChange = (e) => {
    const {
      name,
      value
    } = e.target;

    this.setState({
      [name]: value
    })
  }

  onChangeValue = (e) => {
    const {
      name,
      value
    } = e.target

    this.setState({
      values: {
        ...this.state.values,
        [name]: value
      }
    })
  }

  onCustomerMarketChange = (e) => {
    const {
      name,
      checked
    } = e.target;

    const markets = this.state.values.customerMarketTypes;
    if (checked) {
      markets.push(customerMarket[name]);
    } else {
      const index = markets.indexOf(customerMarket[name]);
      markets.splice(index, 1);
    }

    this.setState({
      values: {
        ...this.state.values,
        customerMarketTypes: markets
      }
    })
  }

  onAddCustomerGroup = (groupId, name, date) => {
    this.setState({
      values: {
        ...this.state.values,
        customerGroups: [
          ...this.state.values.customerGroups,
          {
            id: groupId,
            name: name,
            date: date
          }
        ]
      }
    })
  }

  onSave = (e) => {
    e.preventDefault();
    const validation = validateObject(validationRules, this.state.values)

    if (!validation.valid) {
      this.setState({
        errors: {
          ...validation.properties
        },
        hasError: true
      })

      return;
    } else if (this.state.hasError && validation.valid) {
      this.setState({
        errors: {},
        hasError: false
      })
    }

    this.onSaveCustomerEdit(this.state.values);
  }

  onClose = (e) => {   
    this.props.dispatch(toggleEdit())
  }

  setInitialValues = () => {
    const {
      contactPerson,
      emailAddress,
      emailAddressWayBills,
      salesPerson,
      telephone,
      strategy,
      grossPotential,
      totalSales,
      customerTypeId,
      customerMarketTypes,
      emailAddressInvoices
    } = this.props.customerInfo;

    const lng = this.props.translations.customer;

    this.setState({
      values: {
        ...this.state.values,
        contactPerson,
        emailAddress,
        emailAddressWayBills,
        salesPerson,
        telephone,
        strategy,
        totalSales,
        grossPotential,
        customerTypeId,
        customerMarketTypes,
        emailAddressInvoices
      },
      salesStrategy: [
        { id: salesStrategy.flagShipAccount, name: lng.flagShipAccount },
        { id: salesStrategy.investToGrow, name: lng.investToGrow },
        { id: salesStrategy.maintain, name: lng.maintain },
        { id: salesStrategy.serveEfficiently, name: lng.serveEfficiently }
      ]
    })
  }

  onSaveCustomerEdit = values => {
    const id = this.props.customerInfo.id;
    this.props.dispatch(updateCustomer(id, values));    
  }

  componentDidMount() {
    this.setInitialValues();
  }

  render() {
    const {
      loading,
      customerTypes,
      customerGroups,
      error,
      currencyCode,
      customerInfo
    } = this.props;

    const {
      values,
      errors
    } = this.state;

    const lng = this.props.translations;
    return (
      <div className="customerEdit">
        <FloatingWindow
          title={lng.common.edit}
          draggable={false}
          showHeader={true}
          onClose={this.onClose}>
          <form onSubmit={this.onSave}>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <DetailsEdit
                  onChange={this.onChangeValue}
                  language={lng}
                  errors={errors}
                  values={values}
                  customerTypes={customerTypes}
                  salesStrategy={this.state.salesStrategy} />
              </div>
              <div className="col-xs-12 col-md-6">
                <h4 className="groupHeader">{lng.customer.workingWith}</h4>
                <Checkbox
                  label={lng.common.personalVehicleTire}
                  name="personalVehicle"
                  value={customerMarket.personalVehicle}
                  checked={values.customerMarketTypes.indexOf(customerMarket.personalVehicle) >= 0}
                  onChange={this.onCustomerMarketChange} />
                <br />
                <Checkbox
                  label={lng.common.truckTire}
                  name="truck"
                  value={customerMarket.truck}
                  checked={values.customerMarketTypes.indexOf(customerMarket.truck) >= 0}
                  onChange={this.onCustomerMarketChange} />
                <br />
                <Checkbox
                  label={lng.common.loaderAndTractorTire}
                  name="loaderAndTractor"
                  value={customerMarket.loaderAndTractor}
                  checked={values.customerMarketTypes.indexOf(customerMarket.loaderAndTractor) >= 0}
                  onChange={this.onCustomerMarketChange} />
                <CustomerGroupsEdit
                  addText={lng.common.add}
                  headerText={lng.common.customerGroups}
                  activeCustomerGroups={customerInfo.customerGroups}
                  customerGroups={customerGroups}
                  onAddGroup={this.onAddCustomerGroup} />
                <div className="col-xs-12 col-sm-8">
                  <h4 className="metricsHeader">{lng.common.editKeyMetrics}</h4>
                  <DefaultInput
                    type="number"
                    label={lng.customer.totalSales + (' ' + currencyCode)}
                    error={errors.totalSales}
                    name="totalSales"
                    value={values.totalSales ? values.totalSales.toString() : ''}
                    onChange={this.onChangeValue} />
                  <DefaultInput
                    type="number"
                    label={lng.customer.foreCast + (' ' + currencyCode)}
                    name="grossPotential"
                    error={errors.grossPotential}
                    value={values.grossPotential ? values.grossPotential.toString() : ''}
                    onChange={this.onChangeValue} />
                </div>
              </div>
            </div>
            <div className="center">
              {error ? <ErrorMessage /> : ''}
              <Button type="submit" text={lng.common.save} />
            </div>
          </form>
          {loading ? <LoadingOverlay /> : ''}
        </FloatingWindow>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  translations: state.app.language,
  customerInfo: state.customer.details.info,
  currencyCode: state.app.currencyCode,
  customerGroups: state.customerGroups.items,
  customerTypes: state.customerTypes.items,
  loading: state.customer.customerEdit.saving,
  error: state.customer.customerEdit.error
})

export default connect(mapStateToProps)(CustomerEdit);
